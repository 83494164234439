<div class="container">

    <form method="POST">
        <div class="row">
            <div class="input-field col s12" >
                <label for="email">Email</label>
                <input type="email" name="email" id="email" />
            </div>
        </div>

        <div class="row">
            <div class="input-field col s12" >
                <label for="password">Senha</label>
                <input type="password" name="password" id="password" />
            </div>
        </div>

        <div class="row">
            <div class="input-field col s12 m4 l4" >
                <a class="waves-effect waves-light btn btn-large center" style="width: 100%;">Entrar</a>
            </div>
            <div class="input-field col s12 m4 l4" >
                <a class="waves-effect waves-light right">Esqueceu a senha</a>
            </div>
        </div>
    </form>

</div>